import {
  Box,
  Button,
  Card,
  Collapse,
  Container,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import React, { Fragment, useEffect } from "react";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import papperClouldCrmbg from "../images/v2/faq/papper-cloud-crmbg.png";
import pricingbg from "../images/v2/faq/pricingbg.png";
import careerFaqBg from "../images/v2/faq/career-faq-bg.png";
import useWidth from "../hooks/useWidth";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Scrollspy from "react-scrollspy";
import SEO from "../components/SEO";
import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";

const faqUseStyles = makeStyles((theme) => ({
  smeImage: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  SmeStyling: {
    width: "80%",
    margin: "20px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px auto 0",
    },
  },
  smeFooterText: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tab: {
    "& .MuiTab-wrapper": {
      alignItems: "flex-start !important",
    },
  },
  faqroot: {
    position: "relative",
  },
  mailChimpAndFAQ: {
    background: "linear-gradient(to bottom, #069cb8, #00b8d8)",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  papperCloudCrmFaq: {
    background: `url(${papperClouldCrmbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pricingFaq: {
    background: `url(${pricingbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  grantRated: {
    background: "linear-gradient(to top, #e7f2fe 101%, #fff)",
  },
  careersfaq: {
    background: `url(${careerFaqBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  sideBar: {
    position: "sticky",
    top: "0px",
  },
  overflowVisble: {
    overflow: "visible",
  },
  mobilepad: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "15px",
      paddingLeft: "15px",
    },
  },
  Scrollspy: {
    marginTop: "80px",
    padding: 0,
    borderLeft: `1px solid ${theme.palette.divider}`,
    listStyleType: "none",
    height: "250px",
    "& li": {
      fontSize: "18px",
      color: "red",
      paddingLeft: "20px",
      borderLeft: `2px solid transparent`,
      marginBottom: "16px",
    },
    "& a": {
      color: "gray",
      textDecoration: "none",
    },
  },
  activeTab: {
    borderLeft: `2px solid orange !important`,
    "& a": {
      color: "#000",
      textDecoration: "none",
    },
  },
  faqBody: {
    background: "yellow",
    height: "500px",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  faqCard: {
    borderRadius: "20px",
    boxShadow: "1px 1px 10px 0 rgba(0, 0, 0, 0.16)",
  },
  faqAnchor: {
    "& a": {
      color: "#198cb5",
    },
  },
}));
const FaqCrm = () => {
  const classes = faqUseStyles();
  const width = useWidth();
  const [value, setValue] = React.useState(0);
  const [activeCalendar, setActiveCalendar] = React.useState(0);
  const salesCRMSolution = [
    {
      title: "What is a CRM system?",
      description: (
        <>
          CRM or Customer Relationship Management is software that manages your
          relationships with your existing and potential customers. Know more
          about CRM{" "}
          <a href="https://blog.peppercloud.com/complete-guide-to-crm-software/">
            here
          </a>
          .
        </>
      ),
    },
    {
      title: "What is cloud-based CRM?",
      description:
        "Cloud-based CRM is a CRM where the data is stored or hosted on secure encrypted cloud servers and can be accessed from anywhere with an internet connection.",
    },
    {
      title: "What are the types of CRM software?",
      description: (
        <>
          CRM are mainly of three types: Collaborative CRM, Operational CRM and
          Analytical CRM. Pepper Cloud is a sales CRM which is a type of
          operational CRM. Read more about different types of CRM software{" "}
          <a href="https://blog.peppercloud.com/complete-guide-to-crm-software/#different-types-of-crm">
            here
          </a>
          .
        </>
      ),
    },
    {
      title: "What is a sales CRM?",
      description:
        "A sales CRM software is a tool for managing your prospects and customers throughout the sales process.",
    },
    {
      title: "I’m an SME. Do I need a sales CRM?",
      description: (
        <>
          Any SME with an intention to scale up would certainly need a CRM. A
          sales CRM will help nurture the leads, enhance customer experience,
          and increase team productivity and efficiency. Know more about it{" "}
          <a href="https://blog.peppercloud.com/crm-for-small-businesses-what-how-and-why/#do-small-businesses-need-crm">
            here
          </a>
          .
        </>
      ),
    },
    {
      title: "How does CRM help sales?",
      description: (
        <>
          Pepper Cloud CRM system can help you improve sales by:
          <ul>
            <li>
              Improving sales efficiency. The tool helps you prioritise hot
              leads that are likely to convert into winning opportunities based
              on customer interactions with your company.
            </li>
            <li>
              Boosting sales effectiveness. The tool helps you realise the tight
              time to engage with your customers.{" "}
            </li>
            <li>
              Increasing upsell and cross-sell opportunities. Pepper Cloud sales
              CRM platform helps you to identify all the cross-selling and
              upselling opportunities.
            </li>
            <li>
              Reducing sales cycle time. By providing you with an overall view
              of your sales process and sales pipeline, the team can work on
              closing deals in the shortest time possible.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "How can building the sales pipeline in CRM helps my business?",
      description: (
        <>
          A sales pipeline helps you visualise your customer data and sales
          opportunities. Learn more about the sales pipeline and how you can
          utilise it for your business in this{" "}
          <a href="https://blog.peppercloud.com/an-ultimate-guide-to-sales-pipeline/">
            blog
          </a>
          .
        </>
      ),
    },
  ];
  const paperCloudCrm = [
    {
      title: "What type of CRM is Pepper Cloud?",
      description: (
        <>
          Pepper Cloud is{" "}
          <a href="https://peppercloud.com/">Singapore’s #1 sales CRM</a> that
          helps you to track, automate, and maximise your sales with various
          features and integrations.
        </>
      ),
    },
    {
      title: "How secure is my data in Pepper Cloud CRM?",
      description: (
        <>
          Your data is secure with Pepper Cloud CRM as we ensure{" "}
          <a href="https://peppercloud.com/crm-data-security-protection">
            multi-level authentication{" "}
          </a>
          at the application level. Our security infrastructure has a Secure
          Socket Layer (SSL) is a standard to provide communication security
          across all channels.
        </>
      ),
    },
    {
      title: "Can I export all my data if I stop using Pepper Cloud CRM?",
      description: (
        <>
          Yes. Your data is your property. Export it whenever you like. You can
          also schedule an automatic data export with Pepper Cloud.
        </>
      ),
    },
    {
      title:
        "I’m not finding the integration I need in Pepper Cloud CRM. What to do?",
      description: (
        <>
          We have open APIs which you can use to integrate your favourite apps
          with Pepper Cloud CRM.{" "}
          <a href="https://peppercloud.com/contact">
            Contact our friendly support team{" "}
          </a>
          for more information.{" "}
        </>
      ),
    },
    {
      title: "Where is the headquarters of Pepper Cloud CRM?",
      description: (
        <>
          Pepper Cloud is based out of Singapore and has branches in various
          other locations. Pepper Cloud has consistently proved to be
          Singapore’s #1 sales CRM for small and medium enterprises.
        </>
      ),
    },
    {
      title: "What language does Pepper Cloud CRM support?",
      description: (
        <>
          Currently, Pepper Cloud is available only in English and Chinese
          (simplified). Soon, you will enjoy our services in Thai as well.
        </>
      ),
    },
  ];
  const pricing = [
    {
      title: "How can we choose among various pricing options?",
      description: (
        <>
          Choose the starter pack if you are moving from manual management or
          spreadsheets to CRM. A business plan is good for companies who are
          already using an existing CRM but now shifting to a cloud-based
          solution. For big and heavy users, enterprise plans are the best as it
          comes with unlimited priority support.
        </>
      ),
    },
    {
      title: "Can we change our pricing plan between our billing periods?",
      description: (
        <>
          You can move from the monthly billing cycle to the annual billing
          cycle anytime you want. However, we do not support moving from a
          yearly plan to a monthly plan before the expiry of the period. For
          shifting to enterprise-level plans, please{" "}
          <a href="https://peppercloud.com/contact">contact our sales team</a>.
        </>
      ),
    },
    {
      title: "How to avail PSG grant for Pepper Cloud CRM?",
      description: (
        <>
          <a href="https://peppercloud.com/contact">Contact</a> our friendly
          customer support. They will help you. You can learn more about{" "}
          <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/">
            PSG grants{" "}
          </a>
          on our blog.
        </>
      ),
    },
  ];
  const grantOnCRMSoftware = [
    {
      title: "Which grants can I avail through Pepper Cloud?",
      description: (
        <>
          <p>You can avail PSG grant and EDG grant through Pepper Cloud.</p>
          <p>
            If you are a Singapore-registered SME and is looking to adopt IT
            solutions and equipment to digitally transform your business
            processes, you can opt for the PSG grant. To apply for a PSG grant,
            you must select a vendor from the pre-approved PSG vendor list. You
            can learn more about{" "}
            <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/">
              what is PSG, its eligibility criteria and the PSG application
              process
            </a>{" "}
            in our blog.
          </p>
          <p>
            On the contrary, the EDG grant is not limited to digital solutions
            but supports a wider range of business upgrading solutions. Hence,
            the companies would need to submit individual project proposals with
            their business plans and project outcomes for evaluation. You can
            read more about the{" "}
            <a href="https://blog.peppercloud.com/an-overview-of-enterprise-development-grant-edg/">
              EDG grant, its ligibility criteria, application process of EDG
            </a>{" "}
            and other details in our blog.
          </p>
          <p>
            Pepper Cloud helps SMEs to avail applicable grants and assist in
            their sales growth.
          </p>
          <p>
            You can also read{" "}
            <a href="https://blog.peppercloud.com/a-guide-to-singapore-government-grants/">
              “A Guide to Singapore Government Grants”
            </a>{" "}
            to get an overview of other Sinapore grants for SMEs.
          </p>
        </>
      ),
    },
    {
      title: "What is EDG grant?",
      description: (
        <>
          <p>
            EDG or Enterprise Development Grant is a type of Singapore grant
            that is managed by{" "}
            <a href="https://www.enterprisesg.gov.sg/">Enterprise Singapore</a>.
            This grant supports projects that help you upgrade your business,
            innovate or venture overseas. The three main pillars that EDG grant
            supports are: Core Capabilities, Innovation and Productivity, and
            Market Access.
          </p>
          <p>
            Know{" "}
            <a href="https://blog.peppercloud.com/an-overview-of-enterprise-development-grant-edg/">
              how to apply for EDG grant
            </a>{" "}
            in our blog.
          </p>
        </>
      ),
    },
    {
      title: "What is PSG grant?",
      description: (
        <>
          <p>
            PSG or Productivity Solutions Grant is an pre-approved SME grant
            offered by Singapore government and managed by{" "}
            <a href="https://www.enterprisesg.gov.sg/financial-assistance/grants/for-local-companies/productivity-solutions-grant">
              Enterprise Singapore
            </a>
            . The grant is in supportive of small and medium businesses that are
            keen on adopting IT solutions to enhance business processes. This
            may include implementation of CRM, Accounting tools, HR systems,
            etc.
          </p>
          <p>
            Know more about the PSG grant{" "}
            <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/">
              here
            </a>
            .{" "}
          </p>
        </>
      ),
    },
    {
      title: "How to apply for a PSG grant for Pepper Cloud CRM?",
      description: (
        <ul>
          <li>
            <a href="https://peppercloud.com/contact">Contact</a> our support
            staff and request a quotation.
          </li>
          <li>
            Log in to the{" "}
            <a href="https://www.businessgrants.gov.sg/">
              Business Grants Portal
            </a>{" "}
            and submit an application.
          </li>
          <li>
            After you get an approval from Enterprise Singapore, login to
            Business Grants Portal and accept letter of offer.
          </li>
          <li>
            Complete the CRM implementation and training within stipulated time
            and use the CRM solution at least for a month before applying for
            grant claim.
          </li>
        </ul>
      ),
    },
    {
      title: "Is my company eligible for PSG grant?",
      description: (
        <>
          <p>
            You are eligible for the PSG grant if your business fulfils the
            following criteria:
          </p>
          <ul>
            <li>Your company is registered/incorporated in Singapore.</li>
            <li>Your company has at least 30% local shareholding.</li>
            <li>
              The group annual sales turnover of your company is not more than
              S$100 million, or the company’s group employment size is not more
              than 200 workers.
            </li>
          </ul>
          <p>
            If you are eligible,{" "}
            <a href="https://peppercloud.com/contact">contact</a> our sales team
            right away. We will guide you through the next steps.
          </p>
        </>
      ),
    },
    {
      title: "Can sole proprietor apply for psg grant",
      description:
        "Yes, you are eligible as long as fulfill the eligibility criteria of PSG grant. Refer the previous question for eligibility criteria.",
    },
    {
      title: "When should I consider applying for an EDG grant?",
      description: (
        <>
          <p>You should consider applying for EDG Grant if:</p>
          <ul>
            <li>
              The solution that you wish to implement is not offered by PSG
              pre-approved vendors.
            </li>
            <li>The solution involves heavy customisation.</li>
            <li>
              The company’s group annual sales turnover is more than S$100
              million, or the company's group employment size is more than 200
              workers and hence your company is not eligible for PSG.
            </li>
          </ul>
        </>
      ),
    },
    {
      title:
        "What is the processing and claims disbursement timelines for EDG applications?",
      description: (
        <>
          According to{" "}
          <a href="https://www.enterprisesg.gov.sg/financial-assistance/grants/for-local-companies/enterprise-development-grant/apply/application#:~:text=A%20complete%20submission%20will%20usually,you%20submit%20your%20project%20application.">
            Enterprise Singapore
          </a>
          , a complete submission will usually take eight to twelve weeks from
          the time you submit your project application and the{" "}
          <a href="https://www.enterprisesg.gov.sg/financial-assistance/grants/for-local-companies/enterprise-development-grant/apply/claims#:~:text=The%20approved%20claim%20amount%20will,of%20project%20deliverables%20AND%20expenses.">
            approved claim
          </a>{" "}
          amount will be processed within six to eight weeks via GIRO to your
          designed bank account after successful verification of project
          deliverables and expenses.
        </>
      ),
    },
    {
      title: "How will the EDG disbursement take place?",
      description: (
        <>
          EDG-approved amount will be credited directly to the company’s
          designated bank account through GIRO, after the approval of your
          claims.
        </>
      ),
    },
    {
      title:
        "What is the processing and claims disbursement time for PSG applications?",
      description: (
        <>
          <p>
            According to{" "}
            <a href="https://www.enterprisesg.gov.sg/financial-assistance/grants/for-local-companies/enterprise-development-grant/apply/application#:~:text=A%20complete%20submission%20will%20usually,you%20submit%20your%20project%20application.">
              Enterprise Singapore
            </a>
            , if the company has submitted the GIRO form, funds will be
            disbursed in up to 14 working days from the date of claim approval.
            However, incomplete submissions will result in delays.
          </p>
          <p>
            Companies are encouraged to submit their claims after they have
            fully deployed, providing documents to show the use of the solution
            for at least one month. Incomplete submissions will result in
            delays.
          </p>
        </>
      ),
    },
    {
      title: "How will the PSG disbursement take place?",
      description: (
        <>
          PSG-approved amount will be credited directly to the company’s
          designated bank account through GIRO, according to{" "}
          <a href="https://www.enterprisesg.gov.sg/financial-assistance/grants/for-local-companies/enterprise-development-grant/apply/application#:~:text=A%20complete%20submission%20will%20usually,you%20submit%20your%20project%20application.">
            Enterprise Singapore
          </a>
          .
        </>
      ),
    },
  ];
  const careers = [
    {
      title: "How to apply for jobs at Pepper Cloud?",
      description: (
        <>
          You can apply for a position at Pepper Cloud through our
          <a href="https://peppercloud.com/careers/"> career page</a>.
        </>
      ),
    },
    {
      title: "How long may it take to get feedback about my application?",
      description:
        "We always do our best to give feedback quickly. After we receive your application, one of our recruiters will analyse your resume and inform you about the next stages.",
    },
    {
      title:
        "The webpage is showing me errors when I try to apply. What should I do?",
      description: (
        <>
          <p>Try checking the following points:</p>
          <ul>
            <li>
              If you are applying through your phone, try applying through a
              computer just to be sure.
            </li>
            <li>
              If you have issues with the internet connection, try reloading the
              application.
            </li>
          </ul>
          <p>
            If you have checked the points mentioned above and the application
            still didn’t go through, send us an email with your resume & cover
            letter at{" "}
            <a href="mailto:hiring@peppercloud.com">hiring@peppercloud.com</a>.
            Alternatively, you can also{" "}
            <a href="https://peppercloud.com/contact">contact us</a> .{" "}
          </p>
        </>
      ),
    },
    {
      title:
        "Who should I contact with additional questions about Pepper Cloud’s hiring process?",
      description:
        "The easiest way would be to apply for the position. After applying, the recruiter will directly get in touch with you to assist you with the hiring process.",
    },
    {
      title:
        "There are not any suitable roles open for me on the webpage. What can I do if I still want to join?",
      description: (
        <>
          We recommend you visit us later. Keep an eye on our{" "}
          <a href="https://peppercloud.com/careers">careers page</a> for an
          updated list of CRM jobs. You can also send your resume to{" "}
          <a href="mailto:hiring@peppercloud.com">hiring@peppercloud.com</a>{" "}
          mentioning the position you wish to apply for.
        </>
      ),
    },
    {
      title: "Do you have any internship programs?",
      description: (
        <>
          We welcome full-time internships for 6 months. We also provide
          pre-placement offers to candidates based on their performance during
          internships.
        </>
      ),
    },
    {
      title: "How can I prepare for an interview at Pepper Cloud?",
      description: (
        <>
          <p>
            We strongly believe that a candidate is suitable for the job
            position if their principles align with our
            <a href="https://peppercloud.com/careers"> work ethics</a>. Taking
            ownership, being proactive, and staying committed are a few of these
            principles.
          </p>
          <p>
            Alongside, we appreciate if you conduct your research and learn
            about our company before the interview. You can learn more about the
            company from our{" "}
            <a href="https://www.facebook.com/groups/peppercloudsalescommunity">
              Sales community
            </a>
            ,{" "}
            <a href="https://www.facebook.com/PepperCloudCRM">Facebook page</a>,{" "}
            <a href="https://www.linkedin.com/company/13318755/admin/">
              LinkedIn page
            </a>
            ,{" "}
            <a href="https://www.youtube.com/channel/UCZEdLzIpORF29VuemXUn5NQ">
              Youtube{" "}
            </a>
            channel, and our website.
          </p>
        </>
      ),
    },
    {
      title: "For jobs without deadlines, when can I apply for these jobs?",
      description: (
        <>
          Pepper Cloud’s search ends only after finding the right talent. So, if
          you find a job post without the last date to apply, we recommend you
          send your resume as quickly as possible.
        </>
      ),
    },
    {
      title: "What benefits does Pepper Cloud offer to the employees?",
      description: (
        <>
          <ul>
            <li>Remote work opportunity</li>
            <li>Great work environment</li>
            <li>Competitive salary</li>
            <li>Friendly policies</li>
            <li>Flexible leave policy</li>
            <li>Rewards and recognition</li>
            <li>Career growth</li>
          </ul>
        </>
      ),
    },
  ];
  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <SEO
        canonical="/crm-faq"
        description=" FAQs on Sales, CRM system, Singapore grants on CRM Software, Pepper Cloud CRM, WhatsApp CRM, Pricing & Career: What is CRM? What are types of CRM? What are PSG and EDG grants?"
        keywords="CRM FAQ,crm knowledge base, Sales FAQs, crm questions,  What is CRM?, CRM, Sales CRM"
        pathname="/crm-faq"
        title="Sales and CRM FAQs | Knowledge Base | Pepper Cloud CRM"
      />
      <Box className={classes.mailChimpAndFAQ} py={6}>
        <Container>
          <Grid alignItems="center" container justify="center" spacing={7}>
            <Grid item lg={6} md={6} sm={12}>
              <Box textAlign={{ sm: "center" }}>
                <HeaderTypography
                  color="#fff"
                  component="h1"
                  fontSize={40}
                  fontWeight={600}
                  mt={0}
                  mb={2}
                >
                  Frequently Asked Questions (FAQs)
                </HeaderTypography>
                <ParagraphTypography color="#fff" mb={5} mt={0}>
                  Get answers to the most-asked questions about Pepper Cloud
                  CRM, Singapore grants, Career, and more.
                </ParagraphTypography>
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid lg={2} md={2}></Grid>
            </Hidden>
            <Grid
              component={Box}
              item
              lg={4}
              maxWidth="100%"
              md={4}
              paddingLeft="20px"
              sm={12}
            >
              <Box textAlign={{ sm: "center" }}>
                <StaticImage
                  alt="Pepper Cloud CRM - FAQs"
                  className={classes.crmheadimg}
                  placeholder="blurred"
                  src="../images/v2/faq/mail-chimp-faq.png"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box px={8} className={classes.mobilepad}>
        <Grid
          alignItems="flex-start"
          container
          direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
          justify="center"
          className={classes.overflowVisble}
        >
          <Hidden smDown>
            <Grid item md={2} sm={12} className={classes.sideBar}>
              <Scrollspy
                items={[
                  "section-1",
                  "section-2",
                  "section-3",
                  "section-4",
                  "section-5",
                ]}
                currentClassName={classes.activeTab}
                className={classes.Scrollspy}
                offset={-2}
              >
                <li>
                  <a href="#section-1">Sales CRM Solution</a>
                </li>
                <li>
                  <a href="#section-2">Pepper Cloud CRM</a>
                </li>
                <li>
                  <a href="#section-3">Pricing</a>
                </li>
                <li>
                  <a href="#section-4">Grant on CRM Software</a>
                </li>
                <li>
                  <a href="#section-5">Careers</a>
                </li>
              </Scrollspy>
            </Grid>
            <Grid lg={1} md={1}></Grid>
          </Hidden>
          <Grid item md={9} sm={12} className={classes.faqAnchor}>
            <section id="section-1">
              <Box
                paddingTop={8}
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box>
                  <HeaderTypography component="h2" mt={0} fontSize={35}>
                    Sales CRM Solution
                  </HeaderTypography>
                  <Card className={classes.faqCard}>
                    <Box padding={3}>
                      {salesCRMSolution.map((each, index) => (
                        <div key={each.title}>
                          <Box my={2}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent="flex-start"
                              onClick={() =>
                                setActiveCalendar(
                                  index === activeCalendar ? -1 : index
                                )
                              }
                            >
                              <IconButton
                                aria-label="Expand or Collapse"
                                className={classes.expendButton}
                                color="default"
                              >
                                {index !== activeCalendar ? (
                                  <AddIcon fontSize="small" />
                                ) : (
                                  <RemoveIcon fontSize="small" />
                                )}
                              </IconButton>
                              <Box
                                component="h3"
                                mt={0}
                                fontSize={18}
                                fontWeight="600"
                                m={0}
                                ml={3}
                              >
                                {each.title}
                              </Box>
                            </Box>
                            <Collapse in={activeCalendar === index}>
                              <ParagraphTypography
                                color="#2e3f4f"
                                fontSize={18}
                                mr={6}
                                mt={2}
                                ml={8.5}
                              >
                                {each.description}
                              </ParagraphTypography>
                            </Collapse>
                          </Box>
                          <Divider color="#707070" />
                        </div>
                      ))}
                    </Box>
                  </Card>
                </Box>
              </Box>
            </section>
            <section id="section-2">
              <Box
                paddingTop={8}
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box>
                  <HeaderTypography component="h2" mt={0} fontSize={35}>
                    Pepper Cloud CRM
                  </HeaderTypography>
                  <Card className={classes.faqCard}>
                    <Box padding={3}>
                      {paperCloudCrm.map((each, index) => (
                        <div key={each.title}>
                          <Box my={2}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent="flex-start"
                              onClick={() =>
                                setActiveCalendar(
                                  index === activeCalendar ? -1 : index
                                )
                              }
                            >
                              <IconButton
                                aria-label="Expand or Collapse"
                                className={classes.expendButton}
                                color="default"
                              >
                                {index !== activeCalendar ? (
                                  <AddIcon fontSize="small" />
                                ) : (
                                  <RemoveIcon fontSize="small" />
                                )}
                              </IconButton>
                              <Box
                                component="h3"
                                mt={0}
                                fontSize={18}
                                fontWeight="600"
                                m={0}
                                ml={3}
                              >
                                {each.title}
                              </Box>
                            </Box>
                            <Collapse in={activeCalendar === index}>
                              <ParagraphTypography
                                color="#2e3f4f"
                                fontSize={18}
                                mr={6}
                                mt={2}
                                ml={8.5}
                              >
                                {each.description}
                              </ParagraphTypography>
                            </Collapse>
                          </Box>
                          <Divider color="#707070" />
                        </div>
                      ))}
                    </Box>
                  </Card>
                </Box>
              </Box>
            </section>
            <section id="section-3">
              <Box
                paddingTop={8}
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box>
                  <HeaderTypography component="h2" mt={0} fontSize={35}>
                    Pricing
                  </HeaderTypography>
                  <Card className={classes.faqCard}>
                    <Box padding={3}>
                      {pricing.map((each, index) => (
                        <div key={each.title}>
                          <Box my={2}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent="flex-start"
                              onClick={() =>
                                setActiveCalendar(
                                  index === activeCalendar ? -1 : index
                                )
                              }
                            >
                              <IconButton
                                aria-label="Expand or Collapse"
                                className={classes.expendButton}
                                color="default"
                              >
                                {index !== activeCalendar ? (
                                  <AddIcon fontSize="small" />
                                ) : (
                                  <RemoveIcon fontSize="small" />
                                )}
                              </IconButton>
                              <Box
                                component="h3"
                                mt={0}
                                fontSize={18}
                                fontWeight="600"
                                m={0}
                                ml={3}
                              >
                                {each.title}
                              </Box>
                            </Box>
                            <Collapse in={activeCalendar === index}>
                              <ParagraphTypography
                                color="#2e3f4f"
                                fontSize={18}
                                mr={6}
                                mt={2}
                                ml={8.5}
                              >
                                {each.description}
                              </ParagraphTypography>
                            </Collapse>
                          </Box>
                          <Divider color="#707070" />
                        </div>
                      ))}
                    </Box>
                  </Card>
                </Box>
              </Box>
            </section>
            <section id="section-4">
              <Box
                paddingTop={8}
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box>
                  <HeaderTypography component="h2" mt={0} fontSize={35}>
                    Grant on CRM Software
                  </HeaderTypography>
                  <Card className={classes.faqCard}>
                    <Box padding={3}>
                      {grantOnCRMSoftware.map((each, index) => (
                        <div key={each.title}>
                          <Box my={2}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent="flex-start"
                              onClick={() =>
                                setActiveCalendar(
                                  index === activeCalendar ? -1 : index
                                )
                              }
                            >
                              <IconButton
                                aria-label="Expand or Collapse"
                                className={classes.expendButton}
                                color="default"
                              >
                                {index !== activeCalendar ? (
                                  <AddIcon fontSize="small" />
                                ) : (
                                  <RemoveIcon fontSize="small" />
                                )}
                              </IconButton>
                              <Box
                                component="h3"
                                mt={0}
                                fontSize={18}
                                fontWeight="600"
                                m={0}
                                ml={3}
                              >
                                {each.title}
                              </Box>
                            </Box>
                            <Collapse in={activeCalendar === index}>
                              <ParagraphTypography
                                color="#2e3f4f"
                                fontSize={18}
                                mr={6}
                                mt={2}
                                ml={8.5}
                              >
                                {each.description}
                              </ParagraphTypography>
                            </Collapse>
                          </Box>
                          <Divider color="#707070" />
                        </div>
                      ))}
                      <Container
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          padding: "1rem 0 0",
                        }}
                      >
                        <Box maxWidth={1015} textAlign="center">
                          <Box
                            onClick={() =>
                              window.open(
                                "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                              )
                            }
                            sx={{ cursor: "pointer" }}
                            component={"img"}
                            src={IMDA_SME_Large}
                            alt={"IMDA Pre-approved Solution Provider"}
                            className={classes.smeImage}
                            margin={0}
                            mb={0}
                          />
                          <Box className={classes.SmeStyling}>
                            <ParagraphTypography
                              color="text.secondary"
                              fontSize={16}
                              sx={{ padding: 0, margin: 0 }}
                              textAlign="center"
                              className={classes.smeFooterText}
                            >
                              SMEs are eligible for up to 50% Productivity
                              Solutions Grant (PSG) support for the adoption of
                              Pepper Cloud CRM solution, a Pre-Approved Solution
                              under the IMDA SMEs Go Digital programme.
                            </ParagraphTypography>
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                  </Card>
                </Box>
              </Box>
            </section>
            <section id="section-5">
              <Box
                py={8}
                display="flex"
                justifyContent={{ sm: "center", md: "flex-start" }}
              >
                <Box>
                  <HeaderTypography component="h2" mt={0} fontSize={35}>
                    Careers
                  </HeaderTypography>
                  <Card className={classes.faqCard}>
                    <Box padding={3}>
                      {careers.map((each, index) => (
                        <div key={each.title}>
                          <Box my={2}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent="flex-start"
                              onClick={() =>
                                setActiveCalendar(
                                  index === activeCalendar ? -1 : index
                                )
                              }
                            >
                              <IconButton
                                aria-label="Expand or Collapse"
                                className={classes.expendButton}
                                color="default"
                              >
                                {index !== activeCalendar ? (
                                  <AddIcon fontSize="small" />
                                ) : (
                                  <RemoveIcon fontSize="small" />
                                )}
                              </IconButton>
                              <Box
                                component="h3"
                                mt={0}
                                fontSize={18}
                                fontWeight="600"
                                m={0}
                                ml={3}
                              >
                                {each.title}
                              </Box>
                            </Box>
                            <Collapse in={activeCalendar === index}>
                              <ParagraphTypography
                                color="#2e3f4f"
                                fontSize={18}
                                mr={6}
                                mt={2}
                                ml={8.5}
                              >
                                {each.description}
                              </ParagraphTypography>
                            </Collapse>
                          </Box>
                          <Divider color="#707070" />
                        </div>
                      ))}
                    </Box>
                  </Card>
                </Box>
              </Box>
            </section>
          </Grid>
        </Grid>
      </Box>

      <Hidden smDown>
        <Box>
          <Divider light />
        </Box>
      </Hidden>
    </Fragment>
  );
};

export default FaqCrm;
